.fw {
  display:flex;
  height: 100vh;
  &__left {
    width:50%;
    background-color: $color__primary;
    padding: 13%;
  }
  &__right {
    width: 50%;
    background-color: #FFF;
    padding: 5% 12%;
    margin:0 auto;
  }
}

.fw2 {
  display:flex;
  height: 100vh;
  align-items: center;

  &__left {
    width:30%;
    background-color: $color__primary;
    padding: 16% 10%;
    height: 100%;
  }
  &__right {
    width: 70%;
    background-color: #FFF;
    padding: 1% 3%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    small {
      font-size: 14px;
      font-weight: normal;
    }
    a {
      text-decoration: underline;
    }
  }
}