
// Font-Family
$font__main: 'Sen', sans-serif;
$font__curvy: 'Kaushan Script', cursive;

// Spacer
$spacer:20px;
$breakpoint-alpha: 480px;
//Text Color
$color__text:#000;
$color__heading:#484848;
$color__link:#223d66;
$color__light:#FFF;

//Theme Colors
$color__primary:#444fe5;
$color__secondary:#24678d;
$color__inverse:#2CE080;
$color__primary-hover:#c1312c;
$color__inverse-hover:#edf0f2;




// Text-Colors
$color__light: #FFF;
$color__dark : #333;
$color__anchor: #37A000;



