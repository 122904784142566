html {
  scroll-behavior: smooth;
}
body {
  font-size: 16px;
  font-family: $font__main;
  background-color: #fff;
}
@media (min-width: 1200px) {
}
a {
  color: $color__link; cursor: pointer;
  &:hover {
    color: $color__primary-hover;
  }
}
p {
  line-height: 1.8;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #000;
}
.white-text {
  color: #fff !important;
}
.opaq {
  opacity: 0.6;
}
.burger {
  padding: $spacer * 5 0;
}
.t-burger {
  padding-top: 100px;
}
.gutter {
  padding: 0 $spacer * 5;
}
.gutter-sm {
  padding: 0 $spacer * 2.5;
}
.grey-bg {
  background: #f0f4f7;
}
form label {
  font-size: 0.9em;
}
.form-control {
  border-radius: 4px;
}
.form-control:focus {
  box-shadow: none;
  background: #eee;
}
.chosen-container {
  width: 100% !important;
}
.checkbox label:after,
.radio label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 2px;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
}
.checkbox label,
.radio label {
  margin-bottom: 0;
  cursor: pointer;
}

.radio .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 100%;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  border-radius: 50%;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 13%;
}

.radio .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 13%;
  margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
  opacity: 0.5;
}
//Buttons
.btn {
  border-radius: em(2);
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.btn-primary {
  background-color: $color__primary;
  border-color: $color__primary;
  color:#fff !important;
  border-radius: 4px;
  padding: 6px 20px;
  &:hover {
    background-color: $color__secondary;
    border-color: $color__secondary;
  }
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: $color__primary-hover;
  border-color: $color__primary-hover;
}
.btn-secondary {
  border-radius: 4px;
  padding: 6px 20px;
  background: #eee;
  border-color: #eee;
  color: #000;
  &:hover {
    background: #ddd;
    border-color: #ddd;
    color: #000;
  }
}
.btn-inverse {
  background-color: $color__inverse;
  border-color: $color__inverse;
  color: $color__text;
  &:hover {
    background-color: $color__inverse-hover;
    border-color: $color__inverse-hover;
    color: $color__text;
  }
}
.btn-bordered {
  border: 1px solid $color__primary;
  color: $color__text;
  background-color: transparent;
  &:hover {
    background-color: $color__inverse-hover;
    color: $color__text;
  }
}
.btn-bigger {
  padding: 2em 4em;
  background-color: #fff;
  color: #000;
}
.pb-10 {
  padding-bottom: em(100);
}
.btn-search {
  color: #fff;
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  height: 65px;
  padding-right: 0;
}
.cta-nav {
  display: flex;
  align-items: center;
  background-color: #99d9e8;
  height: 65px;
  a {
    height: 65px;
    display: flex;
    padding: 0 30px;
    align-items: center;
    color: $color__primary;
    transition: 0.3s all;
    font-weight: 500;
    &:hover {
      background-color: $color__primary;
      color: #fff;
    }
    i {
      margin-right: 14px;
    }
  }
  .border-left {
    border-color: rgba(255, 255, 255, 0.2) !important;
  }
}

.dropdown-menu {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0.7rem 0 0;
}
.dropdown-item {
  padding: 0.5rem 1.5rem;
  font-size: 0.9em;
}
.sml-heading {
  color: #000;
  font-size: 0.8em;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  padding-right: 20px;
  display: inline-block;
  margin-bottom: 30px;
  &:after {
    bottom: -75%;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    width: 45px;
  }
}
.offset {
  margin-top: 65px;
}
.capsule {
  padding: 3px 15px;
  border-radius: 25px;
  background: #eee;
  display: inline-block;
  font-size: 0.85em;
}
.blue-dim {
  background: #e8f0fe !important;
  color: blue !important;
}
.pink-dim {
  background: #fce8e6 !important;
  color: red !important;
}
.red-dim {
  background: #feefe3 !important;
  color: red !important;
}
.green-dim {
  background: #e6f4ea !important;
  color: green !important;
}
.badge-primary {
  background: transparent !important;
  color: inherit !important;
}
.btn-default {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 0.9em;
}
.table-hover tbody tr:hover {
  background-color: #f0f4f7;
}
.table-hover th:first-child,
.table-hover th:last-child {
  padding-left: 30px;
  padding-right: 30px;
}
.table-hover td:first-child,
.table-hover td:last-child {
  padding-left: 30px;
  padding-right: 30px;
}
.table-hover td {
  vertical-align: middle;
}
.table thead th {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.6;
}
.divide {
  display: inline-block;
  margin: 0 10px;
  font-size: 20px;
  font-weight: normal;
  color: #777;
}
.navbar-nav {
  .nav-link {
    position: relative;
  }
  .nav-item {
    padding: 15px 10px;

    &.active {
      .nav-link {
        color: $color__primary;
        &:after {
          height: 2px;
          width: 100%;
          background-color: $color__primary;
          position: absolute;
          content: "";
          left: 0;
          bottom: -11px;
        }
      }
    }
  }
}
.date-scroll {
  font-weight: normal;
  font-size: 16px !important;
  i {
    margin: 0 10px;
  }
  .fa-angle-left,
  .fa-angle-right {
    font-size: 20px;
  }
}
.progress-bar {
  background-color: $color__primary;
}
.progress {
  background-color: #ccc;
  height: 22px;
}
.subhead {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
}
.list-group-item.active {
  background-color: transparent;
  color: $color__primary !important;
}
.modal-body,
.modal-header,
.modal-footer {
  padding: 1rem 2rem;
}
.action-area {
  .dropdown-toggle::after {
    border: none !important;
  }
}
.dots {
  font-size: 26px;
  display: inline-block;
  letter-spacing: -1px;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 25px;
  &:hover {
    background: #eee;
  }
}
.bigModal .modal-dialog {
  max-width: 1024px;
}
.green-text {
  color: green;
}
.orange-text {
  color: $color__primary;
}
.smbtn {
  height: 40px;
  width: 40px;
  background: #ddd;
  border-radius: 100%;
  line-height: 40px !important;
  text-align: center;
  display: inline-block;
  &:hover {
    background: grey;
    color: #fff;
  }
}
.navbar-logo {
  display: flex;
  align-items: center;
}
.sml-input {
  width: 80px;
  float: right;
  display: inline-block;
}
.tx-btn {
  display: flex;
  align-items: center;
  a {
    margin-left: 20px;
  }
}
.sml-btn {
  border: 1px solid #ddd;
  padding: 5px 15px;
  font-size: 0.8em;
  border-radius: 30px;
  display: inline-block;
}
.sub-menu {
  list-style: none;
  padding-left: 0;
  padding-bottom: 0;
  margin: 0;
  li {
    display: inline-block;
    margin-right: 15px;
    a {
      font-weight: 600;
      opacity: 0.5;
      font-size: 16px;
      &.kit-navbar {
        font-size: 16px;
        opacity: 0.5;
      }
      &.active {
        font-size: 22px;
        opacity: 1;
      }
    }
  }
}
.b-burger {
  padding-bottom: 100px;
}
.heading {
  font-size: 22px;
  margin: 0;
}
.sml-text {
  font-size: 0.9em;
}
.theme-text {
  color: $color__primary;
}
.btn-group {
  button {
    font-size: 0.9em;
    padding: 5px 15px;
  }
}
.status.circle {
  display: inline-block;
  height: 12px;
  width: 12px;
  background-color: #ddd;
  border-radius: 100%;
  cursor: pointer;
}
.green {
  background-color: #6ec46e !important;
}
.red {
  background-color: #f76464 !important;
}
footer {
  padding: 50px 0;
  p {
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.5);
  }
}
.legends {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    padding-left: 45px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    &:before {
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      display: inline-block;
      background-color: #ddd;
      content: "";
      left: 7%;
      top: 30%;
    }
  }
  .available:before {
    background-color: #2553f8;
  }
  .booked:before {
    background-color: #f17a7a;
  }
  .replenish:before {
    background-color: #000;
  }
  .double:before {
    background-color: #f9e212;
  }
  .long-term:before {
    background-color: #ee7ef0;
  }
  .today:before {
    background-color: #ffa500;
  }
  .wrong:before {
    background-color: brown;
  }
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
.lined-h {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #777;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.btn-orange {
  background-color: orange;
  color: #fff;
  border-color: orange;
  padding: 6px 18px;
}

.pagination {
  margin-top: 20px;
}
.modal {
  &.show {
    padding-right: 0 !important;
  }
}

.form-group {
  .bdHMCv > .wrapper {
    border-radius: 4px !important;
  }
}

.avail-chart .ac-header {
  position: relative;
  z-index: 2;
}
.css-1s2u09g-control {
  border-radius: 3px !important;
  margin: 0 2px;
  .css-qc6sy-singleValue {
  }
}
.mw_100 {
  min-width: 100px;
}
.css-b62m3t-container {
  z-index: 3;
}
.position_up {
  position: relative;
  z-index: 4;
}
.position_up3 {
  position: relative;
  z-index: 3;
}
.css-319lph-ValueContainer {
  /*padding: 6px 8px !important;*/
}

.fix_row {
  min-height: 30px;
  span {
    display: block;
    border-right: 1px solid #ddd;
    margin-right: -8px;
  }
}
.na,
.first_col.na {
  background: #feefe3 !important;
  color: red;
}
.first_col.holiday {
  background: red !important;
  color: white;
}

.loader-icon {
  .img-svg {
    width: 24px;
    height: auto;
    background: none !important;
    rect {
      fill: #fff !important;
    }
  }
}
.av-filter {
  width: calc(100% - 200px);
  .btn-group {
    width: 20%;
    > div {
      width: 96%;
      margin: 0 2%;
    }
    .mw_100,
    button {
      width: 100%;
    }
    &.m-btn-group {
      button {
        border: 1px solid rgba(179, 179, 179, 1);
        border-radius: 5px;
        box-shadow: none !important;
        padding: 7px;
      }
    }
  }
}

.authentication_area {
  .logo_1 {
    width: 90%;
    max-width: 250px;
    margin: auto;
  }
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
  .navbar-toggler-icon {
    background: url("../images/menu.png") center no-repeat;
    background-size: 24px auto;
  }
}
.btn {
  &.btn-block {
    padding: 6px 10px;
  }
}
.btn-clear {
  float: right;
}
.addmsg {
  /*position: absolute; width: 100%; text-align: center;*/
}

.css-1pcexqc-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #eee;
}
.purchase_date {
  position: relative;
  z-index: 4;
  input[type="text"] {
    width: 100%;
    border: 1px solid #cccccc !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 0.52rem 0.75rem;
    border-radius: 4px 0 0 4px;
  }
  .d-flex {
    .inputdata {
      width: calc(100% - 90px);
    }
  }
  .dateslot {
    width: 90px;
    .css-1s2u09g-control {
      margin-left: 0;
      border-radius: 0 4px 4px 0 !important;
      border-left: none;
    }
  }
  .css-319lph-ValueContainer {
    padding: 2px 4px !important;
    .css-6j8wv5-Input {
      padding-top: 0;
      padding-bottom: 0;
    }
    input[type="text"] {
      border: none !important;
    }
  }
  .col-md-3 {
    input[type="text"] {
      border-radius: 4px !important;
    }
  }
  .col-md-9 {
    padding-right: 0 !important;
  }
  .col-md-6:first-child {
    padding-right: 7px !important;
  }
  .col-md-6:last-child {
    padding-left: 7px !important;
  }
}

.collapse1 {
  display: block !important;
  height: 0px;
  opacity: 0;
  transition: 0.3s ease all;
  position: relative;
  z-index: -1;
  &.show1 {
    height: auto;
    opacity: 1;
    z-index: 1;
    &:after,
    &:before {
      position: absolute;
      content: "";
    }
    &:after {
      width: 15px;
      height: 15px;
      background: #fff;
      border: 1px solid #ddd;
      right: 40px;
      top: -8px;
      transform: rotate(45deg);
      border-bottom: none;
      border-right: none;
    }
    /*&:after{ width:1px; height:1px; right: 43px; top: -11px; transform: rotate(45deg); border: 4px solid $color__primary; border-top-color: transparent; border-left-color: transparent; }
    &:before{ width: 2px; height: 13px; background: $color__primary; right: 47px; top: -15px;}*/

    .card {
    }
  }
}

.capsul_kit {
  background-color: rgb(217, 217, 217);
  color: rgb(134, 137, 142);
  border-radius: 30px;
  font-size: 11px;
  padding: 6px 10px 4px;
  margin: 2px 4px 2px 0;
}
.kitname {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #000;
}

.form-control {
  &.rounded-right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &.rounded-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.table-scroll {
  .data_date {
    th {
      border-bottom: 0 !important;
      background: #f1f1f1;
    }
  }
}
.btn {
  &.btn-sm1 {
    padding: 3px 10px;
    font-size: 0.75rem;
    margin: 0.25rem;
    min-width: 95px;
  }
  &.btn-outline-primary {
    border-radius: 1rem;
    border-color: #444fe5 !important;
    color: #444fe5 !important ;
    &:hover {
      background-color: #444fe5 !important;
      color: #fff !important ;
    }
  }
}
.react-datepicker-popper {
  z-index: 9 !important;
}
.sc-hKMtZM {
  position: relative;
  border: 1px solid rgb(223, 225, 229);
  border-radius: 24px;
  background-color: white;
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  svg {

    position: absolute;
    left: 10px;
    top: 12px;
  }
  .clear-icon svg {
    position: absolute;
    right: 10px;
    top: 12px;
    left: unset;
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
    padding-left: 40px;
    border-radius: 24px;
    &:focus {
      background: none;
    }
  }
  .suggestions {
    overflow: auto;
    max-height: 300px;
    border-top: 1px solid rgb(223, 225, 229);
    list-style: none;
    margin-left: 0px;
    padding-left: 14px;
    li {
      margin: 8px 0;
      position: relative;
      padding-left: 27px;
      i {
        position: absolute;
        left: 0;
        top: 6px;
        font-size: 14px;
      }
    }
  }
}

.sc-hKMtZM > input {
  padding-left: 55px !important;
}
.form-group .bdHMCv > .wrapper,.form-group .bdHMCv > .wrapper:hover{ border:none !important;box-shadow: none !important;}

.color-blue{color:blue}
.filter_anchor{ margin-right:10px; display: inline-block; cursor: pointer;
  img{ width:24px; height:auto }
}
.filter_area{ margin-top: 35px;}

.surgeons-comment {
  .list {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    .list-area {
          padding: 8px 0px;
          position: relative;
          width: 95%;
          padding-bottom: 21px;
        // &::before{
        //   content: "";
        //   position: absolute;
        //   width: 16px;
        //   height: 16px;
        //   background: #ebecf1;
        //   left: -7px;
        //   transform: rotate(45deg);
        // }
      h4 {
        font-size: 20px;
      }  
      .date {
        font-size: 13px;
        color: #757778;
      }
      .comment-text {
        p {
          margin-bottom: 0;
          font-size: 15px;
          color: #878b8f;
          word-wrap: break-word;
        }
      }
    }  
  }
}
.comment-bages{
    min-width: 26px;
    height: 26px;
    text-align: center;
    position: absolute;
    top: -13px;
    right: -12px;
    font-size: 12px;
    color: rgb(0, 0, 0);
    padding: 4px;
    z-index: 1;
}
.col .react-datepicker-wrapper{width:auto}
.remove-rounder .sc-hKMtZM {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #ced4da;
}
.remove-rounder .sc-hKMtZM input {
    padding-left: 15px !important;
}
.btn-default{border-radius: 8px;border-color: #666;}
.label_adjust{ max-width:350px;
  .btn-clear{ position:absolute; right: 0; bottom: -15px}
}
a[disabled] { opacity:0.5; cursor: no-drop !important; border-color:#ccc !important;
 &:hover{color:#223d66 !important}
}
.col-md-4{
  >.label_adjust{ max-width:420px;
    .label_adjust{ max-width:140px;}
  }
}
.table-scrollbar{
  tbody{display: block; max-height: 300px; overflow: auto; 
  }
  tbody, thead{
    tr{width: 100%; display: inline-table;}
  }
}
.pd-5{padding-bottom:40px !important}
.edit_fld{ position: absolute;
    right: 20px;
    top: 0;
    width: 26px;
    height: 26px;
    text-align: center;
    border: 1px solid #ddd;
    line-height: 21px;
    border-radius: 100%;z-index: 1;
    cursor: pointer;
    i{ font-size: 12px;}
  } 
/*.download-info1{background-color:#8a8fe3;padding-top: 5px;padding-bottom: 4px;position: relative;top: -2px;
  &:hover{background-color:#8a8fe3 !important; }
}*/
.download-info{color:#7f85dd; font-size: 13px;
  &:hover{color:#444fe5 !important; }
}

.custom_fltr{ display: inline-block; vertical-align: middle; margin-right: 15px; position: relative;z-index: 2;
  >a{ display:block; padding:10px 5px 0px 5px; cursor: pointer;
    svg{ width:24px; height:auto }
    path{ fill:#444fe5 }
  }
  .filter_section{ position:absolute; right:0; top:45px; width: 650px;
    .card-header{
      h5{ display:flex; align-items:center; font-size: 18px;
        svg{ width:18px; height:auto; margin-right:5px }
      }
    }
    .card-header, .card-footer{ padding: .25rem .75rem;}
  }
}
.hide{display:none}
input.bg-hide{
    background: none !important;
    border-color: transparent !important;
   
  }
.fixTableHead {
      overflow-y: auto;
      height: 380px;
}
.fixTableHead thead th {
  position: sticky;
  top: -1px;
  background-color:#f1f1f1;
  opacity:1;
  z-index:1;
}
.fixTableHead  td {
  position: relative;
  
}
table {
  border-collapse: collapse;        
  width: 100%;
}

.css-b62m3t-container{
  .css-2613qy-menu{ z-index: 100 !important; border-top:5px solid #f00 !important}
}
.modal-body{
  .css-6j8wv5-Input{ padding: 0;}
  .css-319lph-ValueContainer {padding: 0px 8px !important;}
}
.header_close-btn{
  .modal-header{ position: relative; padding: 0rem 2rem .75rem 0rem;}
  .close{ position:absolute; right: 0; top: 6px; padding:10px ;}
}
.spinner-loader{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  text-align: center;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.5);
  text-align: center;
                            svg{
                              width: 100px;
                              height: auto;
                              background: transparent;
                            }
                            > div{
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              height: 100%;
                            }
}

tr:nth-child(2) td{
  .navbarDropdownSize.show{
    transform: translate(-120px, 55px) !important;
  }
}

tr td{
  .navbarDropdownSize.show{
    margin-top: -15px !important;
  }
}

.return-read-more-btn{
  text-transform: none;
  border-radius: 64px;
  height: 20px;
  font-size: 11px;
  cursor: pointer;
}

.btn-disabled{
  cursor: not-allowed !important;
}


.loading-state {
  position: absolute;
  top: 100;
  left: 0;
  width: 65vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: #444fe5;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.dropdown-disabled{
  cursor: not-allowed !important;
  opacity: 0.5;
  border-color: #ccc !important;
  input{
    cursor: not-allowed !important;
  }
  .css-1vr111p-option{
    cursor: not-allowed !important;
    input{
      cursor: not-allowed !important;
    }
  }
} 

.loading-state-new {
  top: 100;
  left: 0;
  width: 100%;
  height: 65vh;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-manage{
  text-align: center;
}

.lable-field-position{
  float: right !important;
}

.kit-brown-color .bkd{background-color:brown}
.kit-orange-color .bkd{background-color:orange}

.col-lg-5{
  >.label_adjust{ max-width:500px;
    .label_adjust{ max-width:500px !important; }
  }
}
.date-scroll{
  a{
    &:hover {
      color: $color__primary-hover !important;
    }
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.clear-disabled{
  a{
    cursor: not-allowed !important;
  }
}

.css-1pcexqc-container{
  .css-11unzgr{
    padding-bottom: 30px !important;
  }
} 

.kit-filter {
  width: calc(100% - 0px);
  .btn-group {
    width: 35%;
    > div {
      width: 100%;
      margin: 0 0%;
    }
    .mw_100,
    button {
      width: 100%;
    }
    &.m-btn-group {
      button {
        border: 1px solid rgba(179, 179, 179, 1);
        border-radius: 5px;
        box-shadow: none !important;
        padding: 7px;
      }
    }
  }
  .hospital-filter{
    width: 40% !important;
  }
}

.react-multiselect-checkbox{
  >div>div{
    width: 100% !important;
  }
  .css-1jllj6i-control{
    min-width:auto;
  }
  .css-6y1x9t-control{
    min-width:auto;
  }
  button{
    width: 100% !important;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 7px;
  }
}

.booking-dropdown {
  position: relative;
  border: 1px solid #ddd;
  padding: 7px;
  border-radius: 4px;
  max-width: 250px;
  .dropdown-toggle{
    width: 100%;
  }
  .dropdown-toggle::after {
    position: absolute !important;
    right: 7px !important;
    top: 22px !important;
  }
  .dropdown-menu{
    transform: translate(2px, 45px) !important;
    width: 100% !important;
  }
}

.generatePdf{
  font-size: 14px;
  margin: 0px 10px;
}

.datePicker-mr{
  margin: 0px -10px;
}

.dashboarRightContent{
  display:"flex" !important;
  float:"right" !important;
}
.accordion-header button{    border: none;
  background: none;
  font-size: 18px;}
.accordion-header button:focus{ outline:none}
.p-right{float:right}
.accordion-body .nav-item.active a{background-color: transparent;color: #444fe5 !important;}
h2.accordion-header.active button {color: blue;}
